<script>
import { indicadorEstoque } from "@/components/defaults/indicadorestoque";
import Multiselect from "vue-multiselect";

export default {
  props: { currentEmpresa: { type: Object } },
  components: {
    Multiselect,
  },
  data() {
    return {
      localEstoque: {
        id: -1,
        descricao: null,
        status: true,
        empresa_id: null,
        ind_est: 0,
      },
      listIndicadores: [],
      loader: {
        get: false,
      },

      orientacoes: [
        {
          id: 1,
          nome: "Entrdas e Saídas",
        },
        {
          id: 2,
          nome: "Só entradas",
        },
        {
          id: 3,
          nome: "Só Saídas",
        },
      ],
      tipos: [
        {
          id: 1,
          nome: "Analitica",
        },
        {
          id: 2,
          nome: "Sintetica",
        },
      ],
      select_tipo: null,
      select_orientacao: null,
      planos_contas:[
        {
          id:1,
          nome:"plano 01"
        },
        {
          id:2,
          nome:"plano 02"
        },
      ],
      show_multiselect: false,
      select_plano_conta: null,
      plano:{
        woner_id  : null,
        descricao : null,      
        natureza : null,      
        codigo_reduzido : null,
        classificacao  : null
      }
    };
  },
  mounted() {
    this.$emit("newTitle", "Incluir Plano de Contas");
    this.localEstoque.empresa_id = this.currentEmpresa.id;
    this.listIndicadores = indicadorEstoque;
  },
  methods: {
    abr_multiselect(valor) {
      console.log("entrar");
      if(valor == 1){
         console.log(valor)
        this.show_multiselect = true
      }else {
        console.log("esntrou no elsse" + valor)
        this.show_multiselect = false
      }
    },
    setPost() {
      this.loader.get = true;
      setTimeout(function () {
        this.loader.get = false;
      }, 2000);
      this.$emit("doPost", this.plano);
    },
    selecionar_plano(event){
      this.plano.woner_id = event.id
    },
    selecionar_tipos(event){
      this.plano.classificacao = event.id
    },
    seleciona_orientacoes(event){
      this.plano.natureza = event.id
    }
  },
};
</script>

<template>
  <div class="card">
    <div class="card-body">
      <div class="row">
        <div class="col-md-6">
          <label for="">{{ "*Código" }}</label>
          <input v-model="plano.codigo_reduzido" type="text" class="form-control text-left" />
        </div>
        <div class="col-md-6">
          <label for="">{{ "*Titulo" }}</label>
          <input v-model="plano.descricao" type="text" class="form-control text-left" />
        </div>
      </div>
      <div class="row mb-4 mt-4">
        <div class="col-md-6">
          <label for="">{{ "*Orientação" }}</label>
          <multiselect
            v-model="select_orientacao"
            @select="seleciona_orientacoes($event)"
            :options="orientacoes"
            label="nome"
          >
          </multiselect>
        </div>
        <div class="col-md-6">
          <label for="">{{ "*Tipo" }}</label>
          <multiselect @select="selecionar_tipos($event)" v-model="select_tipo" :options="tipos" label="nome">
          </multiselect>
        </div>
      </div>
      <div class="row d-flex justify-content-center align-items-center mt-4">
        <div class="col-md-12 text-center">
          <b-form-checkbox @change="abr_multiselect($event)">
            Vincular a outro Plano
          </b-form-checkbox>
          <!-- <input type="text" class="form-control text-left"> -->
        </div>
      </div>
      <div class="row" v-if="show_multiselect">
          <div class="col-md-12">
             <multiselect v-model="select_plano_conta" :options="planos_contas" label="nome" @select="selecionar_plano($event)">

             </multiselect>
          </div>
      </div>
    </div>
    <div class="card-body">
      <div class="col-md-12 mt-10">
        <button v-if="!loader.get" class="btn btn-light" @click.prevent="setPost()">
          Salvar
        </button>
        <i
          v-if="loader.get"
          class="fa fa-spinner fa-4x fa-spin text-success"
        ></i>
      </div>
    </div>
  </div>
</template>